'use client';

import { useState } from 'react';
import { useGlobalStore } from 'app/stores/globalStore';
import { useRouter } from 'next/navigation';

type Tab = {
  name: string;
  onClick?: () => void;
  href?: string;
  value: string;
};

export default function TabSelector({
  tabs,
  className = '',
  defaultValue,
}: {
  tabs: Tab[];
  className?: string;
  defaultValue?: string;
}) {
  const router = useRouter();
  const [selectedTab, setSelectedTab] = useState(defaultValue || tabs[0].value);

  const { productFilters, setProductFilters } = useGlobalStore(state => state);
  return (
    <ul
      className={`inline-flex justify-start p-1 font-semibold text-hg-secondary rounded-full bg-hg-cream300 ${className}`}
    >
      {tabs.map(tab => (
        <li
          key={tab.name}
          className={`rounded-full py-3 px-6 text-xs cursor-pointer ${
            selectedTab === tab.value &&
            'bg-hg-secondary text-white pointer-events-none'
          }`}
          onClick={() => {
            setProductFilters({
              ...productFilters,
              category: [],
            });
            setSelectedTab(tab.value);
            tab.onClick && tab.onClick();
            if (tab.href) {
              router.push(tab.href);
            }
          }}
        >
          {tab.name}
        </li>
      ))}
    </ul>
  );
}
