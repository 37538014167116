'use client';

import { useEffect, useState } from 'react';
import { Product, ProductType } from '@interface/product';
import AnimateOnViewport from 'app/(web)/components/common/AnimateOnViewport';
import FullWidthCarousel from 'app/(web)/components/product/fullWidthCarousel';
import { applyFilters } from 'app/(web)/tratamientos/utils/filters';
import { useGlobalStore } from 'app/stores/globalStore';
import { Container } from 'designSystem/Layouts/Layouts';

import TabSelector from '../common/TabSelector';
import CategorySelector from '../filters/CategorySelector';

export default function ProductList({
  products,
  type = ProductType.Medical,
}: {
  products: Product[];
  type?: ProductType;
}) {
  const { productFilters, setProductFilters } = useGlobalStore(state => state);
  const [selectedProductType, setSelectedProductType] = useState(type);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [productsByType, setProductsByType] = useState<Product[]>([]);

  useEffect(() => {
    const initialFilters = { ...productFilters, type: [type] };
    setProductFilters(initialFilters);

    const initialProductsByType = products.filter(
      product => product.type === type
    );
    setProductsByType(initialProductsByType);

    setFilteredProducts(
      applyFilters({ products: initialProductsByType, filters: initialFilters })
    );
  }, [type, products]);

  useEffect(() => {
    setFilteredProducts(
      applyFilters({ products: productsByType, filters: productFilters })
    );
  }, [productFilters, productsByType]);

  useEffect(() => {
    setProductsByType(
      products.filter(product => product.type === selectedProductType)
    );

    setProductFilters({
      ...productFilters,
      type: [selectedProductType],
      category: [],
    });
  }, [selectedProductType]);

  return (
    <div id="pw-homeProducts">
      <Container>
        <TabSelector
          className="mb-4"
          tabs={[
            {
              name: 'Medicina estética',
              onClick: () => {
                setProductFilters({
                  ...productFilters,
                  type: [ProductType.Medical],
                });
                setSelectedProductType(ProductType.Medical);
              },
              value: ProductType.Medical.toString(),
            },
            {
              name: 'Tratamientos de belleza',
              onClick: () => {
                setProductFilters({
                  ...productFilters,
                  type: [ProductType.Esthetic],
                });
                setSelectedProductType(ProductType.Esthetic);
              },
              value: ProductType.Esthetic.toString(),
            },
          ]}
        />
      </Container>
      <Container
        className={`px-0 mb-2 md:px-4 ${selectedProductType === ProductType.Esthetic ? 'mb-12' : ''}`}
      >
        <AnimateOnViewport origin="right">
          <CategorySelector
            products={productsByType}
            type={selectedProductType}
          />
        </AnimateOnViewport>
      </Container>
      <FullWidthCarousel
        className="pb-8"
        type="products"
        items={filteredProducts}
      />
    </div>
  );
}
