'use client';
import { useEffect } from 'react';
import { ApplicationOrigin, useSessionStore } from 'app/stores/globalStore';

export default function SetApplicationOrigin({
  origin,
}: {
  origin: ApplicationOrigin;
}) {
  const { setCurrentOrigin } = useSessionStore(state => state);

  useEffect(() => {
    setCurrentOrigin(origin);
  }, []);

  return <></>;
}
