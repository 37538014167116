import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/poppins-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/poppins-medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/poppins-semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/poppins-bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/gtultramedian-light.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/gtultramedian-bold.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--font-gtUltra\"}],\"variableName\":\"gtUltra\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/helveticaNow-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/helveticaNow-bold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/helveticaNow-extraBold.woff2\",\"weight\":\"800\",\"style\":\"normal\"}],\"variable\":\"--font-helveticaNow\"}],\"variableName\":\"helveticaNow\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(web)/components/common/AnimateOnViewport.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/common/ClinicsSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/common/ConversionUrlSaver.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(web)/components/common/DynamicIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/common/ReviewCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/common/SetApplicationOrigin.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/home/FloatingBottomBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/home/ProductList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/layout/AnalyticsMetrics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/layout/AppointmentButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/layout/ContentViewEvent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/components/layout/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/(web)/components/product/fullWidthCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(web)/trueHair/home/TrueHairHomeHero.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/utils/CheckHydration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["getDiscountedPrice"] */ "/vercel/path0/src/app/utils/common.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/designSystem/Accordion/SimpleAccordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalBackgroundSSR"] */ "/vercel/path0/src/designSystem/Modals/ModalSSR.tsx");
